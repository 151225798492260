import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Card,
  Row,
  Form,
  Table,
  Button,
  Col,
} from "react-bootstrap";
import "./FileUploadForm.css";
import axios from "axios";
import FileUploadProgress from "../fileUpload/FileUploadProgess";
import { fileStatuses } from "../FileStatuses";

const FileUploadForm = (props) => {
  const maxChunkSize = 1048576 * 3; // 3MB chunks
  const completeUrl = "uploadComplete";
  const fileLogsUrl = "getFMEStatuses";

  const inputFile = useRef(null);
  const pollTimerRef = useRef(null);
  const fileGuidMap = useRef(new Map());
  const fileStates = useRef(new Map());

  //#region Properties/Getters/Setters

  const [fileList, setFileList] = useState([]);
  const [fileLogs, setFileLogs] = useState([]);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [disableControls, setDisableControls] = useState(false);

  const getFileLogs = async () => {
    var successFiles = Array.from(fileStates.current, ([k, v]) => [k, v])
      .filter((fileState) => fileState.includes(fileStatuses.success))
      .map((fileState) => fileState[0])
      .join(",");

    await axios
      .get(fileLogsUrl, {
        params: {
          fileNames: successFiles,
        },
      })
      .then((resp) => {
        var logs = [];

        resp.data.forEach((log) => {
          logs.push({
            fileName: log.fileName,
            fmeUploadStatus: log.fmeUploadStatus,
            fileHashMatch: log.hashMatch,
          });
        });

        setFileLogs(logs);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (uploadComplete) {
      pollTimerRef.current = setInterval(getFileLogs, 5000);
    }
    return () => {
      clearInterval(pollTimerRef.current);
    };
  }, [uploadComplete]);

  const getFileContexts = (e) => {
    setFileList([...e.target.files]);

    // initialization of file states and file guids map
    [...e.target.files].forEach((file) => {
      fileGuidMap.current.set(file.name, []);
      fileStates.current.set(file.name, fileStatuses.pending);
    });

    setDisableControls(true);
  };

  const checkFileStates = () => {
    if (
      fileStates.current.size > 0 &&
      fileStates.current.size === fileList.length
    ) {
      // if any one file has successfully uploaded, start polling file statuses from the database
      // otherwise if all files have finished uploading, enable controls
      if (
        Array.from(fileStates.current.values()).indexOf(
          fileStatuses.success
        ) !== -1
      ) {
        setUploadComplete(true);
      }

      if (
        Array.from(fileStates.current.values()).indexOf(
          fileStatuses.pending
        ) === -1
      ) {
        setDisableControls(false);
      }
    }
  };

  const handleFileGuidMapUpdate = (fileName, chunkGuid) => {
    if (fileGuidMap.current.has(fileName)) {
      fileGuidMap.current.get(fileName).push(chunkGuid);
    } else {
      fileGuidMap.current.set(fileName, [chunkGuid]);
    }
  };

  const handleUploadComplete = async (fileName) => {
    const formData = new FormData();
    formData.append("FileGuids", fileGuidMap.current.get(fileName));

    return await axios.post(completeUrl, formData, {
      params: {
        fileName: fileName,
      },
    });
  };

  const handleFileStateChange = (fileName, state) => {
    fileStates.current.set(fileName, state);
    checkFileStates();
  };

  //#endregion

  //#region UI Cleanup

  const resetForm = () => {
    setFileList([]);
    setFileLogs([]);
    if (inputFile.current) {
      inputFile.current.value = "";
    }

    // stop polling and reset all states
    clearInterval(pollTimerRef.current);
    pollTimerRef.current = null;
    setUploadComplete(false);
    fileGuidMap.current = new Map();
    fileStates.current = new Map();
  };

  //#endregion

  //#region UI/Grid/Form Render

  return (
    <Container>
      <Card className="mb-3 border-primary">
        <Card.Header className="text-center text-white bg-primary">
          Manage Files
        </Card.Header>
        <Card.Body>
          <Card.Title className="mb-3">Upload File(s)</Card.Title>
          <Form>
            <Form.Group>
              <Row style={{ padding: "5px 0px" }}>
                <h4>File Transfer System</h4>
              </Row>
              <Row style={{ padding: "10px" }}>
                <Form.Control
                  type="file"
                  ref={inputFile}
                  id="fileUploadControl"
                  onChange={getFileContexts}
                  onClick={resetForm}
                  disabled={disableControls}
                  multiple
                  required
                />
                <Form.Text>Maximum file size is 1GB.</Form.Text>
              </Row>
              <Row style={{ padding: "15px 5px 5px 5px" }}>
                <Table>
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>File Name</th>
                      <th style={{ width: "70%" }}>Upload Progress</th>
                      <th style={{ width: "10%" }}>FME Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fileList.map((file, idx) => {
                      return (
                        <FileUploadProgress
                          key={idx}
                          file={file}
                          maxChunkSize={maxChunkSize}
                          fileLogs={fileLogs}
                          onUploadComplete={handleUploadComplete}
                          onFileStateChange={handleFileStateChange}
                          onFileGuidMapUpdate={handleFileGuidMapUpdate}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
              <Row>
                <Col md className="control-format-right">
                  <Form.Group controlId="clearFormID">
                    <Button
                      variant="primary"
                      className="button_spacer_right"
                      disabled={disableControls}
                      onClick={resetForm}
                    >
                      Clear
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
  //#endregion
};

export default FileUploadForm;
